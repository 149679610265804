<template>
  <div id="order-view" class="order-show py-5 py-lg-6" >
    <div class="mb-3">
      <button @click="$router.go(-1)" type="button" class="btn btn-light"> <i class="mdi mdi-arrow-left"></i> Back</button> 
    </div>
    <div v-if="isLoading">
        <div class="mb-3">
            <b-skeleton animation="wave" height="70px" width="80%"></b-skeleton>
        </div>
        <div class="mb-3">
            <b-skeleton animation="wave" height="50px" width="60%"></b-skeleton>
        </div>
        <div class="row g-4">
          <div class="col-lg-10">
            <div>
                <b-skeleton animation="wave" height="400px" width="100%"></b-skeleton>
            </div>
          </div>
        </div>
    </div>
    <section v-else class="">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
              <div class="col-12">
                  <div class="mb-4">
                    <h3 class="mb-2 font-weight-bold">{{product.name}}</h3>
                    <p>Project Status: <strong class="text-uppercase">{{order.status == 'delivered' ? 'In progress' : order.status }}</strong></p>
                    <p>Order was placed by <strong>{{order.buyer.first_name}}.</strong> <span>Order Number: <strong>#{{order.number}}</strong></span></p>
                  </div>
                  <div class="mb-4 d-flex">
                  <div class="soft-bread w-auto overflow-hidden border shadow-sm">
                    <span class="soft-bread-item border-right"
                      :class="{'bg-success text-white': order.stage == 'design'}"><span style="z-index: 2;">DESIGN</span></span>
                      <span class="soft-bread-item border-right"
                      :class="{'bg-success text-white': order.stage == 'finalist'}"><span style="z-index: 2;">FINALIST</span></span>
                    <span class="soft-bread-item"
                    :class="{'bg-success text-white': order.stage == 'finished'}"><span style="z-index: 2;">FILES</span></span>
                  </div>
                </div>
              </div>
          </div>
          <div class="row g-4">
            <div class="col-lg-10">
              <b-tabs nav-class="nav-tabs order-show-nav brand-nav-design nav-bordered">
                <b-tab :active="activeTab === 1">
                  <template v-slot:title>
                    <i class="mdi mdi-book-outline mr-1"></i>
                    <span class="nav-label">Brief</span>
                  </template>
                  <div class="card">
                    <div class="card-body">
                      <order-brief />
                    </div>
                  </div>
                </b-tab>
                <b-tab :active="activeTab === 2">
                  <template v-slot:title>
                    <i class="mdi mdi-file-image mr-1"></i>
                    <span class="nav-label">Delivery</span>
                  </template>
                  <div>
                    <order-delivery />
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import OrderDelivery from "@/components/order/OrderDelivery.vue"
import OrderBrief from "@/components/order/OrderBrief.vue"

export default {
  name: "order-show",
  components: {
    OrderDelivery,
    OrderBrief,
  },
  data() {
    return {
      isLoading: true,
    }
  },
  watch: {
    "$route":"fetchOrder"
  },
  computed: {
    order(){
        return this.$store.state.orderList.order
    },
    activeUser(){
        return this.$store.state.activeUser
    },
    product(){
      return this.order.cart.product
    },
    activeTab(){
      return this.$route.query.tab ? +this.$route.query.tab : 2;
    },
  },
  methods:{
    fetchOrder(){
      this.isLoading = true
      this.$store.dispatch("orderList/fetchOrder",this.$route.params.orderId)
      .then((response) => {
        this.isLoading = false
        if(!response.data.success){
          this.$router.push({path: '/error-404'})
        }
      })
    },
  },
  created(){
    this.fetchOrder()
  },

}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
.order-show .soft-bread{
  font-weight: 600;
  background: white
}

.order-show .soft-bread .soft-bread-item{
    display:inline-block;
    color:black;
    padding: 0.2rem 0.8rem;
}

</style>
