<template>
  <div>
    <div class="conversation-text">
        <div class="ctext-wrap">
            <div class="mb-1" v-html="preText(message.content)"></div>
            <span class="text-muted font-10">{{message.created_at | date_ago}}</span>
            <message-files v-if="message.files && message.files.length"
            :files="message.files" @showImg="showImg($event)"/>
            <div class="d-flex pt-1 mt-1 border-top">
                <slot name="actions"></slot>
            </div>
            <div v-if="message.replyer_id" class="pr-4 pt-2">
                <h6 class="font-weight-medium my-0 font-sm font-italic">
                    {{message.replyer_id == activeUser.id ? 'You' : 'Buyer'}} replied
                </h6>
                <div>
                  <p class="mb-1"> {{message.reply}} </p>
                  <small class="text-muted">{{message.replied_at | date_ago}}</small>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import MessageFiles from "./MessageFiles.vue"

export default {
  components:{
      MessageFiles
  },
  props:{
      buyer: {
        required: true,
        type:Object
      },
      message: {
        required: true,
        type: Object
      },
      index: {
        required: true,
        type:Number
      },
  },
  computed: {
    activeUser() {
      return this.$store.state.activeUser
    },
  },
  methods:{
    showImg(payload){
      this.$emit("showImg", payload)
    }
  }
}
</script>
